.sponsors {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
}

.sponsors .row {
  padding: 0 0 40px 0;
}

.sponsors .sponsors-content {
  width: 65%;
  justify-content: center;
  margin: 15px;
  padding: 40px;
  align-items: center;
}

/* #plat-sponsors-container {
  background-color: #e5e4e2;
}
#gold-sponsors-container {
  background-color: #e0c56e;
}
#silver-sponsors-container {
  background-color: #c0c0c0;
}
#bronze-sponsors-container {
  background-color: #e3af66;
} */

.sponsors-level {
  font-family: var(--font-3);
  color: var(--main-color);
  font-weight: bold;
  font-size: 120%;
  margin: auto;
}

.sponsors .sponsors-platinum img {
  width: 200px;
  margin: 10px;
}

.sponsors .sponsors-gold img {
  width: 200px;
  margin: 10px;
}

.sponsors .sponsors-silver img {
  width: 150px;
  margin: 10px;
}

.sponsors .sponsors-bronze img {
  width: 100px;
  margin: 10px;
}

.sponsors img:hover {
  -webkit-transition: 0.2s;
  transition: 0.2s;
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

@media screen and (max-width: 800px) {
  .sponsors .sponsors-content {
    justify-content: center;
    margin: auto;
    align-items: center;
  }
  .sponsors .sponsors-platinum img {
    width: 90%;
  }
  .sponsors .sponsors-gold img {
    width: 80%;
  }
  .sponsors .sponsors-silver img {
    width: 70%;
  }
  .sponsors .sponsors-bronze img {
    width: 60%;
  }
}
