.home {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  overflow-x: visible !important;
  /* background-image: url("../../public/home/spacehome.jpeg"); */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.onee {
  width: 41%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: hidden !important;
  background-image: url("../../public/home/castles_landing_page_brown.jpg");
  background-size: auto 100%;
  background-repeat: no-repeat;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.twoo {
  width: 59%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  overflow-x: visible !important;
  /* background-image: url("../../public/home/spacehome.jpeg"); */
  background-color: #7aa16a;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.col {
  float: left;
  justify-content: center;
  align-items: center;
}

.home-content {
  justify-content: center;
  margin: 10px 30px;
  word-wrap: break-word;
  align-items: center;
  padding: 10px 10px;
}

.home-acm {
  font-family: var(--font-1);
  color: var(--secondary-color);
  font-size: 170%;
  margin: 0 0 20px 0;
}

.home-awap {
  font-family: "Rubik";
  font-size: 100%;
  color: var(--text-color);
  font-weight: 900;
  margin: 0 0 5px 0;
}

.home-registration-open {
  font-family: var(--font-3);
  font-size: 150%;
  margin: 0 0 150px 0;
}

.home-register-button-wrapper {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.home-register-button {
  color: var(--secondary-color);
  font-family: var(--font-3);
  font-size: 1.5em;
  border: 0px solid var(--background-color);
  border-radius: 2vh;
  background: var(--background-color);
  margin: 0 0 40px 0;
  padding: 20px 70px;
  width: fit-content;
}

.home-register-button:hover {
  background-color: #dec6a6;
}

.home-register-link {
  text-decoration: none;
  color: var(--secondary-color);
}

.home-event-title {
  font-family: "Georgia";
  font-size: 250%;
  margin: 0 0 40px 0;
  color: var(--secondary-color);
  font-weight: 600;
}

.home p {
  color: var(--background-color);
}

@media screen and (max-width: 800px) {
  .home {
    height: auto;
    font-size: smaller;
    padding: 60px 0 0 0;
  }
  .col {
    width: auto;
  }
  video {
    width: 90%;
    height: auto;
  }
  .home-registration-open {
    margin: auto;
  }
  .home-acm {
    margin: 0 0 10px 0;
  }
  .home-awap {
    font-size: smallest;
  }
  .home-register-button {
    margin: 0 0 10px 0;
  }
  .home-event-title {
    margin: 0 0 20px 0;
  }
}