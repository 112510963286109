.past {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  overflow-x: visible !important;
  flex-wrap: wrap;
  align-items: center;
  background-color: var(--background-color);
}

.past-content {
  width: 75%;
  justify-content: center;
  margin: 15px;
  padding: 40px;
  word-wrap: break-word;
  align-items: center;
  font-family: var(--font-3);
  color: var(--section-text-color);
}

.year-button {
  padding: 15px 18px;
  border-radius: 10px;
  font-family: var(--font-3);
  font-size: 100%;
  font-weight: 600;
  background: var(--secondary-color);
  cursor: pointer;
  color: white;
  text-align: center;
  margin: 15px;
  border: none;
}

.year-button:hover {
  background: var(--main-color);
}

.past .rover {
  transform: scaleX(-1);
}

.past-description {
  text-align: left;
  color: var(--background-color);
}

.past-image {
  width: fit-content;
  height: fit-content;
}

.past-info-box {
  background-color: var(--main-color);
  width: 75%;
  margin: auto;
  padding: 40px;
  border-radius: 8px;
}

.past .row {
  width: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.past .two {
  width: 100%;
  align-items: center;
  justify-content: center;
}

.past p {
  line-height: 200%;
}

.past-title {
  font-size: 120%;
  font-weight: bolder;
  text-align: center;
}

#year2023 {
  background-color: var(--main-color);
}

@media screen and (max-width: 800px) {
  .past {
    height: fit-content;
  }
  .past-content {
    font-size: small;
    padding: 15px;
  }
  .past-box {
    padding: 0 20px;
    height: auto;
  }
  .year-button {
    margin: 10px 5px;
    padding: 5px 10px;
    font-size: larger;
  }
  .past .row {
    width: 100%;
  }
  .past .past-image img {
    height: fit-content;
    width: 100%;
  }
}
