.navbar {
  top: 0vh;
  height: 60px;
  position: sticky;
  z-index: 1000;
  width: 100%;
}

.mobile-button {
  visibility: hidden;
}

.navbar .navbar-logo {
  font-family: var(--section-header-font);
  margin-left: 2em;
  color: white;
  font-size: 2em;
  cursor: pointer;
  display: flex;
  width: fit-content;
}

.navbar .navbar-content {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-color);
  opacity: 1;
}

.navbar .links-container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: 4em;
}

.navbar .links-container .link {
  font-size: 1.3em;
  font-family: var(--font-3);
  margin-left: 1em;
  padding-left: 0.75em;
  cursor: pointer;
  color: white;
}

.navbar .links-container .link:hover {
  color: var(--main-color);
}

.navbar .navbar-logo .img {
  width: 10px;
}

.bm-burger-button {
  visibility: hidden;
  position: fixed;
  width: 36px;
  height: 30px;
  right: 15px;
  top: 15px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: var(--main-color);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 10px;
  width: 10px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: var(--main-color);
}

/* General sidebar styles */
.bm-menu {
  background: var(--background-color);
  padding: 0em 0em 0;
  font-size: 2em;
  height: 100vh !important;
  overflow: hidden !important;
  opacity: 85%;
}

.bm-menu-wrap {
  height: 300%;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: var(--main-color);
  font-family: var(--section-header-font);
  font-size: smaller;
  margin-top: 10vh;
}

/* Individual item */
.bm-item {
  margin-top: 3vh;
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 800px) {
  .navbar {
    position: fixed;
    width: 100%;
    height: 60px;
  }
  .navbar .navbar-logo {
    margin: 15px;
    width: min-content;
    font-size: 130%;
  }
  .links-container {
    visibility: hidden;
    width: 0px;
  }
  .bm-burger-button {
    visibility: visible;
  }
  .mobile-button {
    visibility: visible;
  }
  body * {
    overflow-x: hidden;
  }
}
