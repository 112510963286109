body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Open Sans", "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* import fonts */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css?family=Rubik:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Karla:wght@200;300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css?family=Orbitron:wght@200;300;400;500;600;800&display=swap");
@font-face {
  font-family: "Rubik Bold";
  src: url("font/Rubik-ExtraBold.ttf");
}
